// Use "compact" to define routes paths in the BrowserRouter (Routes.js),

import { extend } from "lodash";

// and use "extended" instead to navigate between routes
export const planningToolRouteUrls = {
    dashboard: {
        compact: "dashboard",
        extended: "/planning-tool/dashboard" 
    },
    groupsCharge: {
        compact: "groups-charge",
        extended: "/planning-tool/groups-charge"
    },
    planning: {
        compact: "planning",
        extended: "/planning-tool/planning"
    },
    planningVisualization: {
        compact: "planningVisualization",
        extended: "/planning-tool/planningVisualization"
    },
    exportExcel: {
        compact: "export-excel",
        extended: "/planning-tool/export-excel"
    },
    groupsAssociation: {
        compact: "groups-association",
        extended: "/planning-tool/groups-association"
    },
    defaultRoutingAssignment: {
        compact: "routing-assignment",
        extended: "/planning-tool/routing-assignment"
    },
    cutoff: {
        compact: "cutoff",
        extended: "planning-tool/cutoff"
    },
    manageDepartments: {
        compact: "manage-departments",
        extended: "/planning-tool/manage-departments"
    },
    manageGroups: {
        compact: "manage-groups",
        extended: "/planning-tool/manage-groups"
    },
    triage: {
        compact: "triage",
        extended: "/planning-tool/triage"
    },
    manageCharges: {
        compact: "manage-charges",
        extended: "/planning-tool/manage-charges"
    },
    orderInfo: {
        compact: "order-info",
        extended: "/planning-tool/order-info"
    },
    tripInfo: {
        compact: "trip-info",
        extended: "/planning-tool/trip-info"
    },
    configurationHub: {
        tripCombinations: {
            compact: "configuration-hub/trip-combinations",
            extended: "/planning-tool/configuration-hub/trip-combinations"
        },
        tripRules: {
            compact: "configuration-hub/trip-rules",
            extended: "/planning-tool/configuration-hub/trip-rules"
        },
        leg1Combinations: {
            compact: "configuration-hub/leg-one-combinations",
            extended: "/planning-tool/configuration-hub/leg-one-combinations"
        },
        leg2Combinations: {
            compact: "configuration-hub/leg-two-combinations",
            extended: "/planning-tool/configuration-hub/leg-two-combinations"
        },
        leg3Combinations: {
            compact: "configuration-hub/leg-three-combinations",
            extended: "/planning-tool/configuration-hub/leg-three-combinations"
        }
    }
}