/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../_metronic/_helpers";
import { setNewBookmark } from '../../../redux/features/bookmarks/bookmarksSlice';
import { ordersNavigationActions } from '../../../redux/features/navigation/navigationSlice';
import { pagesLinks } from "../../../utils/PageUtils";
import { Badge } from '@material-ui/core';
import Timer from 'react-compound-timer';
import { dataAPI } from '../../../api/data/dataAPI';
import { GetUser, hasClaim, hasPlanningToolAccess, UOM_ROLE, UOM_ROLE_ADMIN } from '../../../utils/AuthUtils';
import { planningToolRouteUrls } from '../../../planning-tool/configs/planningToolRouteUrls';
import usePermissions from '../../../planning-tool/utils/hooks/usePermissions';
import { PermissionAreas, PermissionOperations } from '../../../planning-tool/types/permissions';

const AsideMenuListContainer = (props) => {
  const location = useLocation();
  //eslint-disable-next-line
  const [layoutProps, setLayoutProps] = useState(props.layoutProps);
  const [linksList, setLinksList] = useState();
  const getMenuItemActive = (url, hasSubmenu) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const { hasPermission } = usePermissions();

  function disableNotification() {
    props.setNewBookmark(false);
  }

  function reportLinksBuilder(data) {
    var i = 0;

    if (location.pathname.indexOf("/analytics/custom-views") >= 0) {
      props.setNavigatedMenu("Custom Views");
    }
    else {
      while (i < data.length) {
        if (location.pathname.indexOf("/analytics/views/" + data[i].workspaceId + "/" + data[i].reportId + "/" + data[i].reportPageName) >= 0) {
          props.setNavigatedMenu(data[i].description);
          i = data.length;
        }
        i++;
      }
    }

    const linksList = data.map((x, idx) => (
      <li
        className={`menu-item menu-item-submenu`}
        aria-haspopup="true"
        data-menu-toggle="hover"
        key={"menu-report-" + idx}
      >
        <NavLink className="menu-link menu-toggle" onClick={() => { props.setNavigatedMenu(x.description); }} to={"/analytics/views/" + x.workspaceId + "/" + x.reportId + "/" + x.reportPageName}>
          <span className="menu-text">{x.description}</span>
        </NavLink>
      </li>
    ));

    return linksList;
  }

  useEffect(() => {
    let isMounted = true;
    // setIsFetching(true);
    dataAPI.getListMenuReports(
      res => {
        if (isMounted)
          setLinksList(reportLinksBuilder(res.items));
        //setIsFetching(false);
      });

    return () => {
      isMounted = false;
    };

  }, []);


  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard" onClick={() => { props.setNavigatedMenu("Dashboard"); }}>
            <div className="menu-icon-container">
              <div className="menu-icon menu-svg-dashboard"></div>
              <p>Dashboard</p>
            </div>

            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(pagesLinks.orderDetailsRootTitle, false)} ${getMenuItemActive(pagesLinks.viewAllTOsExecutionGrid, false)}`}
          aria-haspopup="true"
        >
          <NavLink
            className={"menu-link " + (checkIsActive(location, pagesLinks.orderDetailsRootTitle) ? "active" : "")}
            to={pagesLinks.viewAllTOsExecutionGrid}
            onClick={() => { props.setNavigatedMenu("TO List"); }}>
            <div className="menu-icon-container">
              <div className="menu-icon menu-svg-tolist"></div>
              <p style={{ width: "100px" }}>Orders Execution</p>
            </div>
            <span className="menu-text">Orders Execution</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {GetUser().customer_view === "TRUE" && <li
          className={`menu-item ${getMenuItemActive(pagesLinks.orderCustomerDetailsRootTitle, false)} ${getMenuItemActive(pagesLinks.viewAllTOsCustomerGrid, false)}`}
          aria-haspopup="true"
        >
          <NavLink
            className={"menu-link " + (checkIsActive(location, pagesLinks.orderCustomerDetailsRootTitle) ? "active" : "")}
            to={pagesLinks.viewAllTOsCustomerGrid}
            onClick={() => { props.setNavigatedMenu("TOC List"); }}>
            <div className="menu-icon-container">
              <div className="menu-icon menu-svg-tocustomerlist"></div>
              <p style={{ width: "100px" }}>Orders Customer</p>
            </div>
            <span className="menu-text">Orders Customer</span>
          </NavLink>
        </li>}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(pagesLinks.tripDetailsRootTitle, false)}`}
          aria-haspopup="true"
        >
          <NavLink
            className={"menu-link " + (checkIsActive(location, pagesLinks.tripDetailsRootTitle) ? "active" : "")}
            to={pagesLinks.viewAllOngoingTripsGrid}
            onClick={() => { props.setNavigatedMenu("Trips List"); }}>
            <div className="menu-icon-container">
              <div className="menu-icon menu-svg-trips-list"></div>
              <p>Trips</p>
            </div>
            <span className="menu-text">Trips</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive(pagesLinks.salesOrderDetailsRootTitle, false)} ${getMenuItemActive(pagesLinks.viewAllSalesOrdersGrid, false)}`}
          aria-haspopup="true"
        >
          <NavLink
            className={"menu-link " + (checkIsActive(location, pagesLinks.salesOrderDetailsRootTitle) ? "active" : "")}
            to={pagesLinks.viewAllSalesOrdersGrid} onClick={() => { props.setNavigatedMenu("SO List"); }}>
            <div className="menu-icon-container">
              <div className="menu-icon menu-svg-solist"></div>
              <p>Sales Orders</p>
            </div>
            <span className="menu-text">Sales Orders</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
              className={`menu-item ${getMenuItemActive(pagesLinks.viewAllUnfilteredWarningGrid, false)}`}
              aria-haspopup="true"
          >
            <NavLink className="menu-link" to={pagesLinks.viewAllUnfilteredWarningGrid} onClick={() => { props.setNavigatedMenu("Warnings"); }}>
              <div className="menu-icon menu-svg-warnings"></div>
              <span className="menu-text">Warnings</span>
            </NavLink>
          </li> */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/favourites", false)}`}
          aria-haspopup="true"
        >
          {props.bookmark && <Timer
            initialTime={9999}
            direction="backward"
            startImmediately={true}
            checkpoints={[
              {
                time: 0,
                callback: disableNotification,
              },
            ]}>
          </Timer>}
          <NavLink className="menu-link" to={pagesLinks.favourites} onClick={() => { props.setNavigatedMenu("Bookmarks"); }}>
            <Badge badgeContent={props.bookmark ? "+1" : 0} className="favourites-badge menu-icon-container">
              <div className="menu-icon menu-svg-bookmarks"></div>
              <p>Bookmarks</p>
            </Badge>
            <span className="menu-text">Bookmarks</span>
          </NavLink>

        </li>
        {/*end::1 Level*/}

        {/* Components */}
        {/* begin::section */}
        {/* <li className="menu-section ">
            <h4 className="menu-text">Components</h4>
            <i className="menu-icon flaticon-more-v2"></i>
          </li> */}
        {/* end:: section */}

        {/* Analytics */}
        {/*begin::1 Level*/}
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            "/analytics", true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/analytics">
            <div className="menu-icon-container">
              <div className="menu-icon menu-svg-analytics"></div>
              <p>Analytics</p>
            </div>
            <span className="menu-text">Analytics</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav" style={{ marginBottom: "45%" }}>
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Analytics</span>
                </span>
              </li>

              {/* Lista link da mostrare nel sottomenu di Analytics */}
              {/*begin::2 Level*/}
              {linksList}
              {/*end::2 Level*/}

              {/* Report Order Variance */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to={pagesLinks.orderVariance} onClick={() => { props.setNavigatedMenu("Order Variance"); }}>
                  <span className="menu-text">Order Variance</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Event Monitoring */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to={pagesLinks.eventMonitoring} onClick={() => { props.setNavigatedMenu("Event Monitoring"); }}>
                  <span className="menu-text">Event Monitoring</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/* Viste personalizzate */}
              {/*begin::2 Level*/}
              <li
                className={`menu-item menu-item-submenu`}
                aria-haspopup="true"
                data-menu-toggle="hover"
              >
                <NavLink className="menu-link menu-toggle" to="/analytics/custom-views" onClick={() => { props.setNavigatedMenu("Custom Views"); }}>
                  <span className="menu-text">Custom Views</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

            </ul>
          </div>
        </li>
        {/*end::1 Level*/}

        {/* Settings */}
        {/*begin::1 Level*/}
        {hasClaim(UOM_ROLE, UOM_ROLE_ADMIN) &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/settings", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/settings" onClick={() => { props.setNavigatedMenu("Configuration"); }}>
              <div className="menu-icon-container">
                <div className="menu-icon menu-svg-settings"></div>
                <p>Settings</p>
              </div>
              <span className="menu-text">Settings</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav" style={{ marginBottom: "75%" }}>
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Settings</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                <li
                  className={`menu-item menu-item-submenu`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to={pagesLinks.settings + "/customers"}>
                    <span className="menu-text">Customers</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

                {/*begin::2 Level*/}
                <li
                  className={`menu-item menu-item-submenu`}
                  aria-haspopup="true"
                  data-menu-toggle="hover"
                >
                  <NavLink className="menu-link menu-toggle" to={pagesLinks.settings + "/custom-views"}>
                    <span className="menu-text">Custom Views</span>
                  </NavLink>
                </li>
                {/*end::2 Level*/}

              </ul>
            </div>
          </li>
        }
        {/*end::1 Level*/}

        {/* PLANNING TOOL */}
        {
          hasPlanningToolAccess() &&
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/planning-tool", true
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink className="menu-link menu-toggle" to="/planning-tool">
              <div className="menu-icon-container">
                <div className="menu-icon menu-svg-analytics"></div>
                <p>Planning Tool</p>
              </div>
              <span className="menu-text">Planning Tool</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav" style={{ marginBottom: "45%" }}>
                <li className="menu-item  menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Planning Tool</span>
                  </span>
                </li>


                {/* Dashboard */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.Dashboard) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.dashboard.extended}>
                      <span className="menu-text">Dashboard</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Groups Charge */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.TakeInCharge) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.groupsCharge.extended}>
                      <span className="menu-text">Get a group</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Configuration Hub */}
                {/*begin::2 Level*/}
                {/* {hasPermission(PermissionAreas.ConfigurationHub) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to="/planning-tool/configuration-hub">
                      <span className="menu-text">Configuration Hub</span>
                      <i className="menu-arrow" />
                    </NavLink> */}

                    {/* 3 Level under Configuration Hub */}
                    {/* <div className='menu-submenu'>
                      <i className='menu-arrow' />
                      <ul className='menu-subnav' style={{ paddingLeft: "20px" }}>
                        {hasPermission(PermissionAreas.ConfigurationHub_TripCombinations) &&
                          <li className='menu-item' aria-haspopup="true">
                            <NavLink className="menu-link" to={planningToolRouteUrls.configurationHub.tripCombinations.extended}>
                              <span className="menu-text">Trip Combinations</span>
                            </NavLink>
                          </li>
                        }
                        {hasPermission(PermissionAreas.ConfigurationHub_TripRules) &&
                          <li className='menu-item' aria-haspopup="true">
                            <NavLink className="menu-link" to={planningToolRouteUrls.configurationHub.tripRules.extended}>
                              <span className="menu-text">Trip Rules</span>
                            </NavLink>
                          </li>
                        }
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations) &&
                          <li className='menu-item' aria-haspopup="true">
                            <NavLink className="menu-link" to={planningToolRouteUrls.configurationHub.leg1Combinations.extended}>
                              <span className="menu-text">Leg 1 Combinations</span>
                            </NavLink>
                          </li>
                        }
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations) &&
                          <li className='menu-item' aria-haspopup="true">
                            <NavLink className="menu-link" to={planningToolRouteUrls.configurationHub.leg2Combinations.extended}>
                              <span className="menu-text">Leg 2 Combinations</span>
                            </NavLink>
                          </li>
                        }
                        {hasPermission(PermissionAreas.ConfigurationHub_LegCombinations) &&
                          <li className='menu-item' aria-haspopup="true">
                            <NavLink className="menu-link" to={planningToolRouteUrls.configurationHub.leg3Combinations.extended}>
                              <span className="menu-text">Leg 3 Combinations</span>
                            </NavLink>
                          </li>
                        }
                      </ul>
                    </div>
                  </li>
                } */}
                {/*end::2 Level*/}

                {/* Export Excel */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.Planning) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.exportExcel.extended}>
                      <span className="menu-text">Export Planning</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}


                {/* Triage */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.Triage) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.triage.extended}>
                      <span className="menu-text">Triage</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Manage Routing */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.RoutingCondition) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.defaultRoutingAssignment.extended}>
                      <span className="menu-text">Manage Routing</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Manage Groups Association */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.GroupCondition) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.groupsAssociation.extended}>
                      <span className="menu-text">Manage Groups Association</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Manage Cutoff */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.Cutoff) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.cutoff.extended}>
                      <span className="menu-text">Manage Cutoff</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Manage Departments */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.ManageDepartment) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.manageDepartments.extended}>
                      <span className="menu-text">Manage Departments</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Manage Groups */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.ManageGroup) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.manageGroups.extended}>
                      <span className="menu-text">Manage Groups</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Manage Charges */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.TakeInCharge, PermissionOperations.ForceTakeOut) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.manageCharges.extended}>
                      <span className="menu-text">Manage Charges</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Order Info */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.InfoOrdersAndTrips, PermissionOperations.View) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.orderInfo.extended}>
                      <span className="menu-text">Order Info</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}

                {/* Trip Info */}
                {/*begin::2 Level*/}
                {hasPermission(PermissionAreas.InfoOrdersAndTrips, PermissionOperations.View) &&
                  <li
                    className={`menu-item menu-item-submenu`}
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    style={{ marginBottom: 40 }}
                  >
                    <NavLink className="menu-link menu-toggle" to={planningToolRouteUrls.tripInfo.extended}>
                      <span className="menu-text">Trip Info</span>
                    </NavLink>
                  </li>
                }
                {/*end::2 Level*/}
              </ul>
            </div>
          </li>
        }
        {/*end::1 Level*/}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
};

const mapStateToProps = function (state) {
  return {
    bookmark: state.bookmark.newBookmark
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setNewBookmark: (value) => {
      dispatch(setNewBookmark(value));
    },
    setNavigatedMenu: (menu) => {
      dispatch(ordersNavigationActions.setNavigatedMenu(menu));
    }
  };
};

export const AsideMenuList = connect(mapStateToProps, mapDispatchToProps)(AsideMenuListContainer);