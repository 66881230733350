import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { CellClassParams, CellValueChangedEvent, GridReadyEvent, IServerSideGetRowsParams, RowClassParams, RowHeightParams, RowNode } from '@ag-grid-community/core';
import { AgGridReact } from "@ag-grid-community/react";
import React, { Dispatch, ForwardedRef, SetStateAction, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { LicenseManager } from '@ag-grid-enterprise/core';

const DEFAULT_PAGE_SIZE = 20;

class Props {
    public gridStyle?: any;
    public columnDefs: any;
    public autoGroupColumnDef?: any;
    public options?: CommonTableOptions;
    public valueSetter?: ((props: any) => boolean);
    public fetchDataFunction?: (pageSize: number, pageIndex: number, orderBy?: string, orderDirection?: string, filterModel?: any, originalFilterModel?: any) => Promise<any>;
    public onGridReady?: (event: GridReadyEvent) => void;
    public callbackOnGetRows?: () => any;
    public dependenciesForUpdatingTable?: any[];
    public getRowId?: (data: any) => any;
    public getRowHeight?: (params: RowHeightParams) => any;
    public rowHeight?: number;
};

type CommonTableOptions = {
    minWidth?: number;
    filter?: boolean;
    floatingFilter?: boolean;
    suppressMenu?: boolean;
    editable?: boolean;
    highlightUnsavedCells?: boolean;
    showRowsNumber?: boolean;
    pageSize?: 10 | 20 | 50 | 60 | 70 | 100;
    isServerSide?: boolean;
    sortable?: boolean;
    showSelectionCheckbox?: boolean;
    suppressCellFocus?: boolean;
    overlayLoadingTemplate?: string;
    context?: any;
    resizable?: boolean;
}

interface UnsavedCell {
    id?: number | null,
    column?: string | null
};

export interface TableRef {
    setTableDataState: Dispatch<SetStateAction<any[]>>;
    redrawTableRows: (rowNodes?: any[] | undefined) => void;
    getTableRows: () => any[];
    getTableNodes: () => any[];
    getSelectedRows: () => any[];
    deleteTableRows: (rows: any[]) => void;
    updateTableRows: (rows: any[]) => void;
    setRowData: (rows: any[]) => void;
    navigateToPage: (pageNumber: number) => void;
    setStopEditing: () => void;
    getUnsavedCells: () => any[];
    setUnsavedCells: (cells: UnsavedCell[]) => void;
    setRowsInError: (rows: number[]) => void;
    getRowsInError: () => any[];
    refreshServerSide: () => void;
    addNewRowInsertion: (rowData?: any) => void;
    cancelNewRowInsertion: () => void;
    getNewRowToInsert: () => any;
    setPinnedBottomData: (data: any) => void;
    getAllGridColumn: () => any[];
    getAgGridRef: () => any;
    showLoadingOverlay: () => void;
    hideOverlay: () => void;
    isGroupColumnCell: (params: any) => boolean;
};

const CommonTable = forwardRef<TableRef, Props>((props: Props, ref: ForwardedRef<TableRef>) => {

    LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-048104 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( ARCESE TRASPORTI SPA )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 19 November 2024 )____[v2]_MTczMTk3NDQwMDAwMA==db1351e3f4f155e80ac349af254d87ce");

    // #region State
    const [tableData, setTableDataState] = useState<any[]>([]);

    const gridRef = useRef<any>(null);
    const unsavedCells = useRef<UnsavedCell[]>([]);
    const rowsInError = useRef<number[]>([]);
    const [newRowToInsert, setNewRowToInsert] = useState<any>(undefined);
    // #endregion

    // #region Memo
    const defaultColDef = useMemo(() => {
        let options = props.options as CommonTableOptions;
        return {
            flex: 1,
            minWidth: options?.minWidth ?? 150,
            sortable: options?.sortable ?? true,
            filter: options?.filter ?? "agTextColumnFilter",
            filterParams: options?.filter ?? {
                filterOptions: ['contains'],
                defaultOption: 'contains',
            },
            floatingFilter: options?.floatingFilter ?? true,
            suppressHeaderMenuButton: options?.suppressMenu ?? true,
            editable: options?.editable ?? true,
            resizable: options?.resizable ?? false,
            valueSetter: props.valueSetter,
            valueFormatter: (params: any): any => {
                return !!!params.value && isEmptyPinnedCell(params)
                    ? createPinnedCellPlaceholder(params)
                    : undefined;
            },
            cellStyle: (params: CellClassParams) => {
                let field = params.colDef?.field;
                let isUnsaved = unsavedCells.current.some(x => (x.id === params.rowIndex && (x.column === null || x.column === undefined)) || (x.id === params.rowIndex && x.column === field));
                let color = "initial";
                if ((params.data?.isHighligthed ?? false) || ((isUnsaved && (options?.highlightUnsavedCells ?? true) && !isGroupColumnCell(params)) || params.node.rowPinned === "top")) {
                    color = 'lightGrey';
                }
                return { backgroundColor: color };
            }
        };
    }, []);
    // #endregion

    // #region CRUD Functions
    const redrawTableRows = (rowNodes: any[] | undefined = undefined) => {
        if (rowNodes !== undefined) {
            gridRef.current.api.redrawRows(rowNodes);
        } else {
            gridRef.current.api.redrawRows();
        }
    };

    const setRowData = (rows: any[]) => {
        gridRef?.current?.api?.setGridOption("rowData", rows);
    };

    const getTableRows = () => {
        let tableRows: any[] = [];
        if (gridRef.current.api === undefined) {
            return tableRows;
        }

        gridRef.current.api.forEachNode((node: RowNode) => {
            let rowToPush = node.data;
            if (rowToPush !== undefined) {
                rowToPush.rowId = node.rowIndex !== null ? node.rowIndex : undefined;
                tableRows.push(rowToPush);
            }
        });

        return tableRows;
    };

    const getSelectedRows = () => {
        return gridRef.current.api.getSelectedRows();
    };

    const getTableNodes = () => {
        let tableNodes: RowNode[] = [];
        gridRef.current?.api?.forEachNode((node: RowNode) => {
            let rowToPush = node.data;
            if (rowToPush !== undefined) {
                rowToPush.rowId = node.rowIndex !== null ? node.rowIndex : undefined;
                tableNodes.push(node);
            }
        });

        return tableNodes;
    };

    const deleteTableRows = (rows: any[]) => {
        gridRef.current.api.applyTransaction({ remove: rows });
    };

    const updateTableRows = (rows: any[]) => {
        gridRef.current.api.applyTransaction({ update: rows });
    };

    const navigateToPage = (pageNumber: number) => {
        gridRef.current.api.paginationGoToPage(pageNumber);
    };

    const setStopEditing = () => {
        gridRef.current.api.stopEditing();
    };

    const getUnsavedCells = () => {
        return unsavedCells.current;
    };

    const setUnsavedCells = (cells: UnsavedCell[]) => {
        unsavedCells.current = cells;
    };

    const setRowsInError = (rows: number[]) => {
        rowsInError.current = rows;
    };

    const getRowsInError = () => {
        return rowsInError.current;
    };

    const onCellValueChanged = (event: CellValueChangedEvent) => {
        if (event.rowPinned === "top") {
            return;
        }

        let oldValue = event.oldValue;
        let newValue = event.newValue;
        if (oldValue != newValue) {
            let newUnsavedCell: UnsavedCell = {
                id: event.rowIndex,
                column: event.colDef.field as string
            };

            let newStateValue = [...unsavedCells.current, newUnsavedCell];

            let uniqueArray = Array.from(new Set(newStateValue.map(x => { return { id: x.id, column: x.column } }))).map(x => {
                return newStateValue.find(y => y.id === x.id && y.column === x.column);
            });

            if (uniqueArray !== undefined) {
                unsavedCells.current = uniqueArray as UnsavedCell[];
                redrawTableRows([event.node]);
                //setUnsavedCells(uniqueArray as UnsavedCell[]);
            }
        }
    };

    const refreshServerSide = () => {
        gridRef.current.api.refreshServerSide();
    };

    const getNewRowToInsert = () => {
        return newRowToInsert != undefined ? { ...newRowToInsert } : undefined;
    };

    const addNewRowInsertion = (rowData: any = {}) => {
        rowData.rowId = -1
        setNewRowToInsert(rowData);
    };

    const cancelNewRowInsertion = () => {
        setNewRowToInsert(undefined);
    };
    // #endregion

    // #region Other Functions   
    const getRowStyle = useCallback(
        (params: RowClassParams<any, any>): any =>
            params.node.rowPinned ? { fontStyle: 'italic' } : {},
        []
    );

    const dependenciesForUpdatingTable = !!props.dependenciesForUpdatingTable ? props.dependenciesForUpdatingTable : [];
    const dataSource = useMemo(() => {
        return {
            getRows: async (params: IServerSideGetRowsParams) => {
                params.api.hideOverlay();

                if (props.fetchDataFunction !== undefined) {
                    let request = params.request;

                    if (!!request && !!request.filterModel) {
                        const paginationProxy = gridRef.current.api?.paginationProxy;
                        const pageSize = !!paginationProxy.pageSizeFromPageSizeSelector ? paginationProxy.pageSizeFromPageSizeSelector : (!!props.options ? props.options.pageSize : DEFAULT_PAGE_SIZE);
                        const pageIndex = paginationProxy.currentPage + 1;

                        let orderBy = request.sortModel.length > 0 ? request.sortModel[0].colId : undefined;
                        let orderDirection = request.sortModel.length > 0 ? request.sortModel[0].sort : undefined;
                        let filterModel: any = {};
                        Object.keys(request.filterModel).forEach((col: any) => {
                            // @ts-ignore
                            let filterColField = request.filterModel[col];
                            let filterType = filterColField.filterType;

                            if (filterType === "set") {
                                filterModel[col] = filterColField.values;
                            } else {
                                filterModel[col] = filterType === 'date' ? filterColField.dateFrom : filterColField.filter;
                            }
                        });

                        const response = await props.fetchDataFunction(
                            pageSize,
                            pageIndex,
                            orderBy,
                            orderDirection,
                            filterModel,
                            request.filterModel
                        );

                        if (response === undefined) {
                            params.fail();
                        } else {
                            if (response.length === 0) {
                                params.api.showNoRowsOverlay();
                            }

                            let objResponse: any = {
                                rowData: response
                            }

                            // Only for Planning Page
                            if (!!response.orders) {
                                objResponse = {
                                    rowData: response.orders,
                                    rowCount: response.totalItems
                                }

                                if (response.orders.length === 0) {
                                    params.api.showNoRowsOverlay();
                                }
                            }

                            params.success(objResponse);

                            if (!!props.callbackOnGetRows) {
                                props.callbackOnGetRows();
                            }
                        }
                    }
                }
            }
        };
    }, [...dependenciesForUpdatingTable]);

    const isEmptyPinnedCell = (params: any) => {
        return params.node.rowPinned === 'top';
        /*return (
            (params.node.rowPinned === 'top' && params.value == null) ||
            (params.node.rowPinned === 'top' && params.value === '')
        );*/
    };

    const createPinnedCellPlaceholder = ({ colDef }: any) => {
        let headerName = colDef.headerName;
        if (headerName === undefined) {
            return "";
        }
        if (colDef.colId !== "ag-Grid-AutoColumn") {
            return colDef.field[0].toUpperCase() + colDef.field.slice(1) + '...';
        }
    };

    const setPinnedBottomData = (data: any) => {
        if (!!gridRef.current && !!gridRef.current.api) {
            gridRef.current.api.setGridOption("pinnedBottomRowData", data);
        }
    };

    const getAllGridColumn = () => {
        if (!!gridRef.current && !!gridRef.current.api) {
            return gridRef.current.api.getAllGridColumns();
        }

        return null;
    };

    const getAgGridRef = () => {
        return gridRef.current;
    };

    const showLoadingOverlay = () => {
        gridRef.current.api.showLoadingOverlay();
    };

    const hideOverlay = () => {
        gridRef.current.api.hideOverlay();
    };

    const isGroupColumnCell = (params: any) => {
        return params.colDef.colId === "ag-Grid-AutoColumn";
    };
    // #endregion

    // #region UseEffect
    useEffect(() => {
        if (newRowToInsert !== undefined) {
            gridRef.current.api.setGridOption("pinnedTopRowData", [newRowToInsert]);
        } else if (gridRef.current.api !== undefined) {
            gridRef.current.api.setGridOption("pinnedTopRowData", undefined);
        }
    }, [newRowToInsert]);
    // #endregion

    const indexSelectionColumn = {
        field: "rowId",
        headerName: "",
        maxWidth: 80,
        filter: false,
        editable: false,
        sortable: false,
        checkboxSelection: (params: any) => (props.options?.showSelectionCheckbox ?? false) && !unsavedCells.current.some(x => x.id === params.rowId && (x.column === null || x.column === undefined)) && !params.node.group,
        cellRenderer: (params: any) => {
            let rowIndexForView = params.node?.rowIndex + 1;
            let rowIndex = params?.data?.rowId;
            return (
                <div className="flex">
                    {((props.options?.showRowsNumber ?? false) && !isEmptyPinnedCell(params) && !!rowIndexForView && rowIndexForView !== -1) &&
                        <span className="mt-[2px]">{rowIndexForView}</span>
                    }
                    {rowsInError.current.includes(rowIndex) && !params.node.group &&
                        <svg className='text-warning ml-2 mt-3' xmlns="http://www.w3.org/2000/svg" width="1.6em" height="1.6em" viewBox="0 0 24 24"><g fill="none"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" /><path fill="currentColor" d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2m0 2a8 8 0 1 0 0 16a8 8 0 0 0 0-16m0 11a1 1 0 1 1 0 2a1 1 0 0 1 0-2m0-9a1 1 0 0 1 1 1v6a1 1 0 1 1-2 0V7a1 1 0 0 1 1-1" /></g></svg>
                    }
                </div>
            );
        }
    };

    const colDef = ((props.options?.showSelectionCheckbox ?? false) || (props.options?.showRowsNumber ?? false)) ? [indexSelectionColumn, ...props.columnDefs] : props.columnDefs;

    useImperativeHandle(ref, () => ({
        setTableDataState,
        redrawTableRows,
        getTableRows,
        getTableNodes,
        getSelectedRows,
        deleteTableRows,
        updateTableRows,
        setRowData,
        navigateToPage,
        setStopEditing,
        getUnsavedCells,
        setUnsavedCells,
        setRowsInError,
        getRowsInError,
        refreshServerSide,
        addNewRowInsertion,
        cancelNewRowInsertion,
        getNewRowToInsert,
        setPinnedBottomData,
        getAllGridColumn,
        getAgGridRef,
        showLoadingOverlay,
        hideOverlay,
        isGroupColumnCell
    }));

    const defaultOverlayLoadingTemplate = '<div aria-live="polite" aria-atomic="true" style="height:100px; width:100px; background: url(/media/svg/general/loadingAnimated.svg) center / contain no-repeat; margin: 0 auto;" aria-label="loading"></div>';

    return (
        <>
            <div className={"ag-theme-alpine"} style={props.gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    // @ts-ignore
                    rowData={(props.options?.isServerSide ?? false) ? undefined : tableData}
                    columnDefs={colDef}
                    defaultColDef={defaultColDef}
                    rowSelection={'multiple'}
                    suppressRowClickSelection={true}
                    rowModelType={(props.options?.isServerSide ?? false) ? 'serverSide' : 'clientSide'}
                    pagination={true}
                    serverSideDatasource={(props.options?.isServerSide ?? false) ? dataSource : undefined}
                    getRowStyle={getRowStyle}
                    suppressCellFocus={(props.options?.suppressCellFocus ?? false)}
                    onCellValueChanged={(e: any) => onCellValueChanged(e)}
                    overlayLoadingTemplate={props.options?.overlayLoadingTemplate ?? defaultOverlayLoadingTemplate}
                    maxConcurrentDatasourceRequests={1}
                    autoGroupColumnDef={props.autoGroupColumnDef}
                    tooltipShowDelay={0}
                    onPaginationChanged={(e) => {
                        if ((props.options?.isServerSide ?? false)) setUnsavedCells([]);
                    }}
                    paginationPageSizeSelector={props.options?.isServerSide === true ? [!!props.options?.pageSize ? props.options?.pageSize : DEFAULT_PAGE_SIZE] : [10, 20, 50, 100]}
                    paginationPageSize={!!props.options?.pageSize ? props.options?.pageSize : DEFAULT_PAGE_SIZE}
                    cacheBlockSize={props.options?.isServerSide ? (!!props.options?.pageSize ? props.options?.pageSize : DEFAULT_PAGE_SIZE) : undefined}
                    maxBlocksInCache={1}
                    tooltipHideDelay={5000}
                    groupDefaultExpanded={props.options?.isServerSide === true ? undefined : 1}
                    onGridReady={props.onGridReady}
                    modules={[ClientSideRowModelModule, ServerSideRowModelModule, SetFilterModule, RowGroupingModule]}
                    tooltipInteraction={true}
                    domLayout='autoHeight'
                    getRowId={props.getRowId}
                    getRowHeight={props.getRowHeight}
                    rowHeight={props.rowHeight}
                    suppressMovableColumns
                    context={!!props.options ? props.options.context : undefined}
                />
            </div>
        </>
    );
});

export default CommonTable;