import { createSlice } from '@reduxjs/toolkit'
import { getCurrentDateTimeSpan } from "../../../utils/DateUtils"

const commonInitialState = {
  // Dashboard Filter Time Span From
  from: getCurrentDateTimeSpan().from,

  // Dashboard Filter Time Span To
  to: getCurrentDateTimeSpan().to,

  // Indicates if Branch List for Dashboard DropDown Filter has been fetched
  branchesFetched: false,

  // Indicates if Customer List & Branch List for Dashboard DropDown Filter have been fetched
  allFetched: false,

  // Ids of Selected Customers in Dashboard Filter
  selectedTradingPartners: [],

  // Ids of Selected Branches in Dashboard Filter
  selectedBranches: [],

  // Ids of Selected Carriers in Dashboard Filter
  selectedCarriers: [],

  // Used for reload Customer List for Dashboard Filter
  requestRefreshTradingPartner: new Date(),

  // Description of selected customers
  selectedTradingPartnersTxt: [],

  // Description of selected branches
  selectedBranchesTxt: [],

  // Description of selected carriers
  selectedCarriersTxt: [],

  // To handle refresh of "from" and "to" everytime changes page (see SubHeaderContainer.js/MapCardContainer.js) 
  hasRefreshedFromAndTo: false,
};

const ordersCustomerFiltersInitialState = {
  ...commonInitialState,
  opt: 3,
};

const ordersExecutionFiltersInitialState = {
  ...commonInitialState,
  opt: 3,
};

const tripsFiltersInitialState = {
  ...commonInitialState,
  opt: 5,
};

const commonReducers = {
  setTimeSpan(state, action) {
    state.from = action.payload.from;
    state.to = action.payload.to;
  },
  setOption(state, action) {
    state.opt = action.payload.opt;
  },

  branchesFetched(state, action) {
    state.selectedBranches = action.payload.ids;
    state.branchesFetched = true;
    if (action.payload.showTO != 'trip') {
      state.allFetched = state.branchesFetched;
      state.opt = 3;
    } else {
      state.allFetched = state.branchesFetched;
      state.opt = 5;
    }
  },
  resetFetched(state) {
    state.branchesFetched = state.allFetched = false;
  },
  setTradingPartners(state, action) {
    state.selectedTradingPartners = action.payload;
  },
  setBranches(state, action) {
    state.selectedBranches = action.payload;
  },
  setCarriers(state, action) {
    state.selectedCarriers = action.payload;
  },

  setTradingPartnersAndBranches(state, action) {
    state.selectedTradingPartners = action.payload.tradingPartners;
    state.selectedBranches = action.payload.branches;
  },
  setTradingPartnersCarriersAndBranches(state, action) {
    state.selectedTradingPartners = action.payload.tradingPartners;
    state.selectedBranches = action.payload.branches;
    state.selectedCarriers = action.payload.carriers;
  },
  setTradingPartnersTxt(state, action) {
    state.selectedTradingPartnersTxt = action.payload;
  },
  setBranchesTxt(state, action) {
    state.selectedBranchesTxt = action.payload;
  },
  setCarriersTxt(state, action) {
    state.selectedCarriersTxt = action.payload;
  },
  resetFrom(state) {
    state.from = getCurrentDateTimeSpan().from;
  },
  resetTo(state) {
    state.to = getCurrentDateTimeSpan().to;
  },
  setHasRefreshedFromAndTo(state, action) {
    state.hasRefreshedFromAndTo = action.payload;
  },
  setAllInfos(state, action) {
    state.allFetched = action.payload.allFetched;
    state.branchesFetched = action.payload.branchesFetched;
    state.from = action.payload.from;
    state.hasRefreshedFromAndTo = action.payload.hasRefreshedFromAndTo;
    state.opt = action.payload.opt;
    state.requestRefreshTradingPartner = action.payload.requestRefreshTradingPartner;
    state.selectedBranches = action.payload.selectedBranches;
    state.selectedBranchesTxt = action.payload.selectedBranchesTxt;
    state.selectedCarriers = action.payload.selectedCarriers;
    state.selectedCarriersTxt = action.payload.selectedCarriersTxt;
    state.selectedTradingPartners = action.payload.selectedTradingPartners;
    state.selectedTradingPartnersTxt = action.payload.selectedTradingPartnersTxt;
    state.to = action.payload.to;
  }
};

const ordersCustomerFiltersSlice = createSlice({
  name: 'ordersCustomerFilters',
  initialState: ordersCustomerFiltersInitialState,
  reducers: commonReducers
});

const ordersExecutionFiltersSlice = createSlice({
  name: 'ordersExecutionFilters',
  initialState: ordersExecutionFiltersInitialState,
  reducers: commonReducers
});

const tripsFiltersSlice = createSlice({
  name: 'tripsFilters',
  initialState: tripsFiltersInitialState,
  reducers: commonReducers
});

const salesOrdersFiltersSlice = createSlice({
  name: 'salesordersFilters',
  initialState: commonInitialState,
  reducers: commonReducers
});

export const ordersCustomerFiltersActions = ordersCustomerFiltersSlice.actions;
export const ordersExecutionFiltersActions = ordersExecutionFiltersSlice.actions;
export const tripsFiltersActions = tripsFiltersSlice.actions;
export const salesOrdersFiltersActions = salesOrdersFiltersSlice.actions;

export const ordersCustomerFiltersReducer = ordersCustomerFiltersSlice.reducer;
export const ordersExecutionFiltersReducer = ordersExecutionFiltersSlice.reducer;
export const tripsFiltersReducer = tripsFiltersSlice.reducer;
export const salesOrdersFiltersReducer = salesOrdersFiltersSlice.reducer;
